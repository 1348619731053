<template>
  <div class="content" ref="content">
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form v-bind="searchLayout">
          <a-row>
            <a-col :sm="8" :lg="6">
              <a-button type="primary" @click="getDataList(1)">刷新</a-button>
              <a-button
                v-action:goods-type="'add'"
                type="primary"
                @click="addForm(() => $refs.eForm.open(null))"
                style="margin-left: 15px"
              >
                添加商品种类
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="datalist"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'isShow'">
              <a-switch
                v-model:checked="record.isShow"
                :checkedValue="1"
                :unCheckedValue="0"
                checked-children="显示"
                un-checked-children="隐藏"
                @change="changeIsShow(record.id, $event)"
              />
            </template>

            <template v-if="column.key === 'sort'">
              <a-tag color="blue">{{ record.sort }}</a-tag>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="editForm(() => $refs.eForm.open(record))">
                  <EditOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <e-form ref="eForm" @ok="formOk" />
    <e-sort ref="eSort" @ok="okSort" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { GoodsCategoryClass } from '@/apis/goods'
import page from '@/mixins/page'
import EForm from '@/components/forms/goods/Category'
import ESort from '@/components/forms/Sort'

import { dataToTree } from '@/utils'

const api = new GoodsCategoryClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    EForm,
    ESort
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)
    const columns = [
      {
        title: '标题',
        dataIndex: 'title',
        key: 'title'
      },
      {
        title: '顺序',
        dataIndex: 'sort',
        key: 'sort',
        width: 60
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 150
      }
    ]
    return {
      loading,
      columns,
      height,
      width
    }
  },
  computed: {
    datalist () {
      const a = dataToTree(this.list, 0)
      return a
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList () {
      this.loading = true
      api.gets().then((resp) => {
        this.list = resp

        this.$store.commit('SET_DATA_GOODSCATEGORYS', resp)
        this.loading = false
        this.getTableHeight()
      })
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.title}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList()
          })
        }
      })
    },
    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },
    changeIsShow (id, e) {
      this.loading = true
      api.show(id, e).then(() => {
        this.$message.success('设置成功！')
        this.getDataList()
      })
    },

    formOk (data) {
      api
        .create(data)
        .then(() => {
          this.loading = false
          this.$message.success('添加成功')
          this.$refs.eForm.onClose()
          this.getDataList()
        })
        .catch((e) => {
          this.loading = false
        })
    },
    okSort (e) {
      if (this.id !== 0) {
        api.sort(this.id, e).then(() => {
          this.$message.success('修改成功')
          this.$refs.eSort.handleClose()
          this.id = 0
          this.getDataList()
        })
      }
    },

    editSort (record) {
      this.id = record.id
      this.$refs.eSort.open(record.sort)
    }
  }
})
</script>

<style lang="less" scoped>

</style>
