<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :loading="loading"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="标题"
          :validate="validateInfos.title"
          v-model:value="modelRef.title"
        />

        <e-cascader title="上级分类" :options="categorys" v-model:value="parentIds" />

        <a-row style="padding-left: 2px">
          <a-col :span="7" :offset="1">
            <e-input-number
              title="顺序"
              v-model:value="modelRef.sort"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { GoodsCategoryClass } from '@/apis/goods'
import { dataToCascader, getParentIdArray } from '@/utils'

const useForm = Form.useForm
const api = new GoodsCategoryClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  props: {
    storeId: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')
    const categorys = ref([])
    const parentIds = ref([])

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      parentIds.value = []
      loading.value = false
      visible.value = false
    }

    const filter = (inputValue, path) => {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    }

    return {
      title,
      visible,
      loading,
      categorys,
      parentIds,
      isEdit,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose,
      filter
    }
  },

  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.title = formData.title
        this.modelRef.parentId = formData.parentId
        this.modelRef.sort = formData.sort
        this.modelRef.id = formData.id

        this.title = '编辑：' + formData.title
        this.isEdit = true
      } else {
        this.modelRef = reactive(api.modelRef)
        this.title = '添加分类'
        this.isEdit = false
      }

      this.getCategorys()
      this.visible = true
    },

    onReset () {
      this.modelRef.title = ''
      this.modelRef.icon = ''
      this.modelRef.thumbnail = ''
      this.modelRef.banner = ''
    },

    onSubmitFun (e) {
      const data = toRaw(e)
      const pid = this.parentIds[this.parentIds.length - 1]
      data.parentId = pid || 0
      if (this.isEdit) {
        api.update(data).then(() => {
          this.loading = false
          this.$message.success('编辑成功')
          this.$emit('ok', null)
          this.onClose()
        })
      } else {
        this.$emit('ok', data)
      }
    },

    setRemark (value, e) {
      const name = this.roles[e.key].name
      this.modelRef.remark = name
    },

    async getCategorys () {
      const list =
        this.$store.state.data.ALL_GOODSCATEGORYS.length > 0
          ? this.$store.state.data.ALL_GOODSCATEGORYS
          : await this.$store.dispatch('GetAllGoodsCategorys')

      this.categorys = dataToCascader(list, 0)

      if (this.isEdit) {
        const parentIds = getParentIdArray(toRaw(list), this.modelRef.id)
        parentIds.sort((a, b) => a - b)
        this.parentIds = parentIds.filter(x => x !== this.modelRef.id)
      }
    },

    emitChooseIcon (e) {
      this.modelRef.icon = e
      console.log(this.modelRef)
    },
    emitChooseResource (e) {
      const image = e.images[0]
      const field = e.field
      this.modelRef[field] = image
    }
  }
})
</script>

<style lang="less" scoped>
</style>
